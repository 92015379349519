$blue: #28B7FF;
$pink: #FF4BBD;
$yellow: #FFFF00;
$darkYellow: #ffef92;
$gold: #FFDA00;
$darkBlue: #0064A0;

@font-face {
  font-family: "Loopiejuice-Regular";
  src: url("Loopiejuice.ttf");
}

@font-face {
  font-family: "Harrington";
  src: url("Harrington.ttf");
}

$font-family-sans-serif: "HelveticaNeue-Light",
"Helvetica Neue Light",
"Helvetica Neue",
Helvetica,
Arial,
"Lucida Grande",
sans-serif;
$font-family-fun: "Loopiejuice-Regular";
// $font-family-serif: Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
