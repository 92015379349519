@import '../common/common';

.App {
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    font-size: 1.2rem;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
}

.body {
    flex: 1 1 auto;
    position: absolute;
    top:0; left: 0; right: 0; bottom: 0;
    overflow: auto;
}

.navbar, .Footer {
    flex: 0 1 auto
}

.row {
    justify-content: center;
}

.flex-centered {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.small-card {
  width: 22rem;
  max-width: 100%;
}

// buttons
.button {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 100px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 5px;
    text-decoration: none;
    color: white;
  }
}

.btn.btn-round {
  border-radius: 6rem;
  padding: .37rem 1.25rem;
}

.btn.display-4 {
  font-size: 3.5rem;
}

.btn-group-toggle {
  margin: 5px;
}

.btn.btn-primary {
  &:not(:disabled):not(.disabled) {
    background-color: darken($color: $blue, $amount: 5);
    border-color: darken($color: $blue, $amount: 5);

    &:hover {
      // background-color: darken($color: $blue, $amount: 8);
      // border-color: darken($color: $blue, $amount: 8);
      box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 5px;

    }

    &:active,
    &.active {
      background-color: $blue;
      border-color: $blue;
      // box-shadow: darken($color: $blue, $amount: 22) 1px 1px 5px 1px;
      box-shadow: darken($blue, 22) 1px 1px 5px 1px;
    }
  }
}

// header

.construction {
  width: 100vw;
  position: relative;
  top: 0;
  left: 0;
  background-color: $yellow;
  color: black;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
}

.navbar {
  box-shadow: 0 2px 10px #0004;
  background-color: white;
  padding: 5px 15px;
  text-align: center;

  height: 3rem;
  background-color: #fff6;
  position: absolute;
  box-shadow: 0 0 12px 8px #fff6;
  z-index: 10;

  > * {
    cursor: pointer;

  }
}

